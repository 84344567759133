.errorPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.errorPage-title {
  font-size: 3rem;
  margin: 10px auto;
}

.wrapper .btn {
  background: rgb(0, 195, 154);
  padding: 0.7em;
  font-size: 1.5rem;
  border-radius: 20px;
  text-decoration: none;
  color: #fff;
}
.wrapper .btn:hover {
  background: rgb(0, 231, 201);
}
