.container-role {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  box-shadow: 20px 20px 40px -6px rgba(0, 0, 0, 0.5);
}

.container-role-sub {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 750px;
}

.role-selection-form {
  height: 420px;
  padding: 3em;
  width: 60%;
  text-align: center;
  background-color: #fff;
}

.role-selection-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.role-selection-title {
  font-weight: bold;
  font-size: 2rem;
  margin-top: 0;
  color: #1653b5;
  margin-bottom: 60px;
}

.role-selection-title-sub {
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 0;
  color: #333333;
}
