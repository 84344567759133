.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8em 2em;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.logo {
  box-shadow: 3px 3px 1px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 4px 1px 3px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 3px 3px 1px rgba(0, 0, 0, 0.25);
}
.btn {
  padding: 10px 20px;
  text-decoration: none;
  margin-left: 5px;
  border-radius: 2em;
  color: #fff;
  font-size: 1.2em;
}
.btn-logout {
  background: transparent;
}
.btn:hover {
  opacity: 0.7;
}
.header-logo {
  height: 60px;
}
/* profile dropdown */
.action .menu {
  position: absolute;
  top: 105px;
  right: 32px;
  padding: 10px 30px;
  background: #fff;
  width: 260px;
  box-sizing: 0 5px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: 0.5s;
  visibility: hidden;
  opacity: 0;
  z-index: 10;
}
.action .menu.active {
  top: 80px;
  visibility: visible;
  opacity: 1;
}
.action .menu::before {
  content: "";
  position: absolute;
  top: -7px;
  right: 35px;
  width: 20px;
  height: 20px;
  background: #fff;
  transform: rotate(45deg);
}
.action .menu h3 {
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  padding: 0.3em 0;
  font-weight: 500;
  color: #2b83e5;
  line-height: 1.2em;
  text-transform: capitalize;
}
.action .menu h3 span {
  font-size: 0.8em;
  color: #2b83e5;
  font-weight: 400;
}
.action .menu ul li {
  list-style: none;
  padding: 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
}
.action .menu ul li a {
  margin-left: 10px;
  display: inline-block;
  text-decoration: none;
  color: #555;
  font-weight: 500;
  transition: 0.5s;
  position: relative;
}
.action .menu ul li a::after {
  bottom: -3px;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #2b83e5;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.action .menu ul li a:hover::after {
  width: 100%;
  left: 0;
}

.action .menu ul li:hover a {
  color: #2b83e5;
  cursor: pointer;
}
