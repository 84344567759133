/* Filter component */

/* dropdown */
.rmsc {
  --rmsc-hover: #c8d8e4 !important;
  --rmsc-selected: #6e8bb8 !important;
  --rmsc-radius: 15px !important; /* Radius */
}

.filter {
  margin: 20px auto;
}
.filter-row {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
}
.first-filter {
  order: 1;
  width: 250px;
  z-index: 999;
}

.second-filter {
  order: 2;
  width: 250px;
}
.third-filter {
  order: 3;
  width: 250px;
}
.filter-button {
  order: 4;
  /* width: 10%; */
}
.dropdown-heading-dropdown-arrow {
  visibility: visible;
}
.dropdown-heading-value .gray {
  visibility: hidden;
  position: relative;
}
.first-filter .gray:after {
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  content: "Select Bank";
}
.second-filter .gray:after {
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
}
.second-filter-product .gray:after {
  content: "Select Product";
}
.second-filter-document .gray:after {
  content: "Select Document";
}
.second-filter-fileStat .gray:after {
  content: "Select File Status";
}
.third-filter .gray:after {
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
}
.third-filter-sampler .gray:after {
  content: "Select Sampler";
}
.third-filter-verifier .gray:after {
  content: "Select Verifier";
}

/* file count */
.badge {
  padding: 3px 5px;
  font-size: 12.025px;
  font-weight: bold;
  white-space: nowrap;
  color: #ffffff;
  background-color: #999999;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
  margin-right: 4px;
}
.badge:hover {
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
}
.badge-allCount {
  background-color: #ed944d;
}
.badge-allocationPendingCount {
  background-color: #48a14d;
}
.badge-verificationPending {
  background-color: #b33f40;
}
.badge-verifiedFileCount {
  background-color: #d2ca00;
}
.badge-completedFileCount {
  background-color: #6577b3;
}
.badge-tabpannel {
  position: absolute;
  background-color: #fff;
  color: #032437;

  top: 0;
  right: 0;
}

/* add file */
.addfile-container {
  margin: 20px auto;
}
.addfile-container-column {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: space-evenly;
}
.addfile {
  margin: 10px 0;
}

.addfile-second {
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  margin: 20px auto;
}

.addfile-third {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* File Attach  */
.drop-file-input {
  position: relative;
  width: 400px;
  height: 200px;
  border: 2px dashed var(--border-color);
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--input-bg);
}

.drop-file-input input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
  opacity: 0.6;
}

.drop-file-input__label {
  text-align: center;
  color: var(--txt-second-color);
  font-weight: 600;
  padding: 10px;
}

.drop-file-input__label img {
  width: 100px;
}

.drop-file-preview {
  margin-top: 30px;
}

.drop-file-preview p {
  font-weight: 500;
}

.drop-file-preview__title {
  margin-bottom: 20px;
}

.drop-file-preview__item {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  background-color: var(--input-bg);
  padding: 15px;
  border-radius: 20px;
}

.drop-file-preview__item img {
  width: 50px;
  margin-right: 20px;
}

.drop-file-preview__item__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.drop-file-preview__item__del {
  background-color: var(--box-bg);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: var(--box-shadow);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.drop-file-preview__item:hover .drop-file-preview__item__del {
  opacity: 1;
}

#addDoc {
  display: flex !important;
}
