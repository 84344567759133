.MuiAutocomplete-paper {
  font-size: 14px !important;
}

.MuiAutocomplete-option[aria-selected="true"] {
  background-color: #0f67f7af !important;
}

.MuiAutocomplete-option.Mui-focused {
  background-color: #578bda77 !important;
}