/* Login Form styling */

.container-supervisor {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  box-shadow: 20px 20px 40px -6px rgba(0, 0, 0, 0.5);
}

.container-supevisor-sub {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 750px;
}

.overlay {
  /* background-color: #1553b5; */
  background-image: linear-gradient(
      rgba(105, 105, 105, 0.5),
      rgba(105, 105, 105, 0.5)
    ),
    url("../../../../utils/assets/images/loginLogoBgImg.jpg");
  height: 420px;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.overlay-sub {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
}
.overlay-sub img {
  margin-bottom: 15px;
}
.overlay-sub span {
  color: #eb8802;
}
.supervisorLoginForm {
  height: 420px;
  padding: 3em;
  width: 60%;
  text-align: center;
  background-color: #fff;
}

.supervisorLoginForm-login-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.supervisorLoginForm-login-title {
  font-weight: bold;
  /* opacity: 0.7; */
  font-size: 2rem;
  margin-top: 0;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); */
  color: #1653b5;
}

.supervisorLoginForm-login-title-sub {
  font-weight: 400;
  /* opacity: 0.7; */
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 60px;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); */
  color: #333333;
}

.supervisorLoginForm-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.supervisorLoginForm-button input[type="submit"] {
  /* margin-top: 10px; */
  width: 120px;
  font-size: 1rem;
  cursor: pointer;
  color: #fff;
  padding: 10px;
  background-color: #114599;
  font-weight: 600;
}
