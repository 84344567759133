.voice_record {
  color: #777;
}
.voice_record-header {
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rec-effect {
  transition: box-shadow 0.33s ease-in-out;
  animation: rec-effect 1s infinite alternate ease-in-out;
}

@keyframes rec-effect {
  0% {
    box-shadow: 0 0 0 15px #1553b5, 170px 0 0 -33px #1553b5,
      -170px 0 0 -33px #1553b5, 125px 0 0 -28px #1553b5,
      -125px 0 0 -28px #1553b5, 75px 0 0 -23px #1553b5, -75px 0 0 -23px #1553b5;
  }
  47% {
    box-shadow: 0 0 0 0 #333, 170px 0 0 -33px #1553b5, -170px 0 0 -33px #1553b5,
      125px 0 0 -18px #1553b5, -125px 0 0 -18px #1553b5, 75px 0 0 -33px #1553b5,
      -75px 0 0 -33px #1553b5;
  }

  100% {
    box-shadow: 0 0 0 0 #333, 170px 0 0 -20px #1553b5, -170px 0 0 -20px #1553b5,
      125px 0 0 -25px #1553b5, -125px 0 0 -25px #1553b5, 75px 0 0 -20px #1553b5,
      -75px 0 0 -20px #1553b5;
  }
}

/* audio player */
.audio-icon {
  width: 90%;
  height: 90%;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.upload_Button {
  display: flex;
  justify-content: center;
}

.player-button {
  background-color: transparent;
  border: 0;
  width: 3em;
  height: 3em;
  cursor: pointer;
  padding: 0;
}

.timeline {
  -webkit-appearance: none;
  width: calc(100% - (3em + 2em + 0.5em));
  height: 0.5em;
  background-color: #6b82a7;
  border-radius: 5px;
  background-size: 0% 100%;
  background-image: linear-gradient(#1553b5, #1553b5);
  background-repeat: no-repeat;
  margin-right: 0.5em;
}

.timeline::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: all 0.1s;
  background-color: #1553b5;
}

.timeline::-moz-range-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: all 0.1s;
  background-color: #1553b5;
}

.timeline::-ms-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: all 0.1s;
  background-color: #1553b5;
}

.timeline::-webkit-slider-thumb:hover {
  background-color: #1553b5;
}

.timeline:hover::-webkit-slider-thumb {
  opacity: 1;
}

.timeline::-moz-range-thumb:hover {
  background-color: #1553b5;
}

.timeline:hover::-moz-range-thumb {
  opacity: 1;
}

.timeline::-ms-thumb:hover {
  background-color: #1553b5;
}

.timeline:hover::-ms-thumb {
  opacity: 1;
}

.timeline::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.timeline::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.timeline::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.sound-button {
  background-color: transparent;
  border: 0;
  width: 2em;
  height: 2em;
  cursor: pointer;
  padding: 0;
}

.download-button {
  background-color: transparent;
  border: 0;
  width: 2em;
  height: 2em;
  cursor: pointer;
  padding: 0;
  padding-left: 5px;
}
